import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pebble Dash`}</h1>
    <p>{`Pebble Dash is a decorative exterior masonry coating applied to a wall using a wet mortar with pebbles (or spar) cast onto it. Traditionally the mortar used in pebbledash was Lime based, but in more recent times cement is the main mortar used. Since the 1990’s a polymer-cement mortar has been available offering a wide range of colours for the mortar layer. Each different product has its own attributes giving advantages and disadvantages.`}</p>
    <p>{`Nowadays Pebble Dash is actually what is called Spar Dash. It is very similar to Pebble Dash, in fact it is applied in exactly the same way.`}</p>
    <p>{`The difference between Pebble Dash and Spar Dash is the aggregate materials used. Where Pebble Dash obviously uses pebbles, Spar Dash uses materials such as chippings, gravel substances and shale. The individual sizes vary from around 3mm to 25mm and are available in almost any colour. A combination of colours can be blended together to achieve flakes or highlights within the wall finish.`}</p>
    <h2>{`Mortar for the Pebbledash`}</h2>
    <p>{`Coloured polymer mortars are an attractive option for any new build or restoration project. The advantage of using a polymer mortar is that it is waterproof and resists attack from algae and lichen.`}</p>
    <p>{`You can choose from a wide range of colours for the backing mortar. The use of colour matched corner beads can make a project really stand out, combined with a wide choice of spar there is plenty of choice.`}</p>
    <p>{`There are instances when it would be unsuitable to use a polymer mortar for pebbledash. One example would be the restoration of a historic building, constructed with lime mortar. Lime mortars and cement mortars behave very differently in structures and have varying tolerances to external factors such as temperature and weather. Due to cements rigidity and lime mortars flexability, the two when together simply cause problems, therefore it is essential they are kept apart. If a cement based mortar was used in this occasion, it would crack and fail in a short amount of time, costing far more to correct.`}</p>
    <p>{`The use of a coloured lime mortar is an attractive option in this case and will restore the buildings original appearance as well as protecting it from the elements for many decades.`}</p>
    <h2>{`Types of Pebble Dash`}</h2>
    <p>{`There are other less known types of pebbledash that NW Renders can apply such as Wet dash (or Roughcast), Tyrolean (or Stucco) and Harling. Here we explain the differences:`}</p>
    <h3>{`Wet Dash (or Roughcast)`}</h3>
    <p>{`This is when Spar or chippings are mixed into the final coat of mortar, then cast or sprayed onto the wall. This is a traditional finish found all around the UK but has generally fallen out of favour in recent times.`}</p>
    <h3>{`Tyrolean or Stucco`}</h3>
    <p>{`This is a traditional finish mainly used in England and Wales. It is applied using a Tyrolean gun and builds up several layers until the desired finish is achieved. Tyrolean is good for decorating walls with existing old or poor condition brickwork.`}</p>
    <h3>{`Harling`}</h3>
    <p>{`Harling is also a traditional finish, applied mainly in Scotland and Ireland. Harling was traditionally a Lime based product cast onto the wall via a flicking method. This involved layering the Lime until reaching the desired effect. This method is also useful for covering up poorly maintained walls or old brickwork.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      